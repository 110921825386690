/*  ===== MOBILE styles (reside at the top and are inherited by all devices) ===== */
/*  ===== See tablet, desktop, and print @media queries at the bottom. */


/*   ----- Imports  -----  */
  /* Add fonts from the app's server 
    @font-face {                
      font-family: 'FontName';
      src: url('FontName.woff2') format('woff2');
    } 
    ...or hosted services like Google Fonts 
    @import url(//fonts.googleapis.com/css?family=Font+Name);
  */
  
  /* @import 'node_modules/modern-normalize/modern-normalize.css'; */
        /* npm install modern-normalize
          Resets elements for browser consistency.  
          https://www.npmjs.com/package/modern-normalize
        */    
        @import url('https://fonts.googleapis.com/css2?family=Anton&family=Ysabeau:wght@300;700&display=swap'); 
        /* Component-specific stylesheets, if any. 
          @import "component1.css";   
        */
        @import "~react-image-gallery/styles/css/image-gallery.css";
        
      /*   ----- Variables  -----  */
      :root {
          --DarkGrayBlue:#434659;
          --MediumBlue:#5080bf;
          --SkyBlueOpacity:#9ac1d9e5;
          --TanBeige:#BF9E75;
          --TanBeigeOp:#bf9e75a9;
          --LightGray: #e0e0e1;
      
        }
        
        /*  ----- Viewport ----- elements inherited by all remaining elements ----- */
        body {       /* Can alternatively define in html {} or :root {} */
          background-color: lightgray;
          /* background-image:url("images/utah-canyon-landscape.jpg"); */
          background-size: cover;
          color:var(--DarkGrayBlue);
          font-family:ysabeau, sans-serif; /* Define with at least one font name and its related generic family name.*/
          font-size:1.3em;
          line-height:1.2em;
          margin:0 auto;
          padding:1%;
          max-width: 1000px;
          min-width: 500px;
          
        }
        
        /*  ----- Page layout blocks -----  */
        header {
          /* background-color: var(--DarkGrayBlue); */
          color: var(--DarkGrayBlue);
          /* padding-bottom:0.2%; */
          margin:20px;
          text-align: left;
          border-bottom: 5px solid var(--DarkGrayBlue);
        }
      .experience {
        font-size:0.85em;
      }
        main {
          background-color: white;
          border: 3px double var(--MediumBlue);
          border-radius: 50px;
          margin:2% 0%;
          padding:4%;
        }
        .project-description{
          border-top: 1px solid black;
          /* border-bottom: 1px solid black; */
        }
        section {}
          article {}
            #topic1 {}
            #topic2 {}
        div {}
        aside {}
        footer {
          text-align: left;
          color: var(--DarkGrayBlue);
        }
        
        /* ----- Anchors ----- Text links */
        /* a {}
        a:link {}
        a:hover {}
        a:active {}
        a:visited {} */
        
        /*  ----- Navigation ----- Override text links for navigation only.  */
        nav {
          text-align: center;
        }
        nav a {
          color: var(--DarkGrayBlue);
          font-size: 0.8em;
          padding: 10px;
          text-decoration: underline ;
      
        }
        nav a:hover {
          color: var(--TanBeige);
        }


        
        /*  -----  Typography  ----- */
        p {}
        br {}
        ol, ul, dl {}
        li {}
        dt {}
        dd {}
        
        h1, h2, h3, h4, h5, h6 {
          font-family:anton;
        } 
        h1 {}
        h2 {}
        h3 {}
        h4 {}
        h5 {}
        h6 {}
        
        abbr {}
        acronym {}
        address {}
        blockquote {}
          q {}
          cite {}
        em {}
        hr {}
        mark {}
        s {}
        span {}
        strong {}
        sub {}
        sup {}
        time {}
        
        /*  ----- Coding or instructional typography ----- */
        pre {}
        code {}
        kbd {}
        samp {}
        var {}
        del {} 
        ins {}
        
        /*  ----- Table ----- */
        table {
          align-items: center;
          font-size: .85em;
          color:var(--DarkGrayBlue);
          width: 100%
        }
        caption {
          text-align: left;
          color:var(--DarkGrayBlue);
          /* background-color:var(--LightGray); */
          margin:2% 0% 0% 0%;
          padding:1%;
          /* border-bottom: 1px solid var(--DarkGrayBlue); */
          /* border-radius:8px; */
        }
        thead {}
        tbody {}
        tfoot {}
        tr {}
        
          /* tr:nth-child(even) td {background-color: var(--SkyBlueOpacity);} */
        /* tr th { 
          align-items: left;
          color:var(--DarkGrayBlue);
          background-color:var(--MediumBlue);
          margin: 3px;
          padding:1%;
          border-bottom: 1px solid var(--LightGray);
       
        } */
        td {
          padding:1% 1%;
        }
       table{
        border-top: 1px solid var(--DarkGrayBlue);
        border-bottom: 1px solid var(--DarkGrayBlue);
       }
       .project-table{
        border:none;
        td{
          /* border:1px solid black; */
        padding:0%;
      width:25%}
       }
        
        /*  ----- Form ----- */
        form, input, button, textarea, select {
          font-family: ysabeau, sans-serif;
        }
        fieldset {
          color:var(--DarkGrayBlue);
          background-color: var(--MediumBlue);
          border:3px solid var(--DarkGrayBlue);    
          margin:1%; 
          padding: 2%;  
       }
          legend {
            color: var(--DarkGrayBlue);
            background-color: var(--TanBeige);
            border: 2px solid var(--DarkGrayBlue);
            margin: 1%;
            padding: .001px 10px;
            border-radius:8px;
            
          }
        label {
          font-weight: bold;
          color: var(--DarkGrayBlue);
          background-color: var(--SkyBlueOpacity);
          display:block;
          margin-top:4%;
          padding: 10px;
          border: 4px solid var(--TanBeige);
          border-radius:8px;
        }
        label.required:after {
          content:" *";
          color:red;
      }
        input, button, textarea, select {
          color: var(--DarkGrayBlue);
          background-color: var(--LightGray);
          font-size:110%;
          margin: 2%;
          padding:1%;
          font-family:inherit;
          border: 3px solid var(--DarkGrayBlue);
          border-radius:8px;
        }
        input, textarea:invalid {
          border:1px solid red;
        }
        input, textarea:valid {
          border:2px solid green;
        }
        input::placeholder {
          color:var(--MediumBlue);
      }
      input[type=checkbox]:checked + label {
        color: green;
      } 
      input:enabled {
        background: var(--LightGray);
      }
        :focus{
          /* background-color: var(--MediumBlue); */
        }
        *:required {
          border: 1px solid red;
        }
          output {}
          meter {}
          progress {}
            optgroup {}
            option {}
       
        
        /* ----- Media ----- */
        .gallery figure {
          width: 400px;
          margin:10px;
          padding:1%;
          border-radius:10px;
          border:3px solid var(--MediumBlue)
          
        }
        .gallery figcaption {
          display:block; 
          padding: 1% 1% 2% 1%;
        }
          figure img {}
        svg {}
        picture {}
          source {}
        iframe {}
        video {}
        audio {}
        embed {}
        object {}
        
        /*  ----- Classes for alignment, positioning, widths, grids, embellishments, and animation -----  */
        .gallery { 
          display: flex;
          flex-flow: row wrap;
        }
        
        .floatright {}
        .floatleft {}
        .center-middle {}
        
        .ten {}
        .twenty {}
        .thirty {}
        .forty {}
        .fifty {}
        .sixty {}
        .seventy {}
        .eighty {}
        
        .radius {}
        .circle {}
        .boxshadow {}
        .tshadow {} 
        .gradient {}
        .shape {}
        
        @keyframes App-logo {}
        
        /*   ===== TABLET  media query overrides mobile styles ===== */
        @media all and (min-width: 600px) {}
        
        /*   ===== DESKTOP  media query overrides mobile and tablet styles ===== */
        @media all and (min-width: 1080px) {}
        
        /*   ===== PRINT  media query overrides previous styles =====  */
        @media print {}










/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/