aside {
    position: fixed;
    right:0;
    top: 0;
    bottom: 0;
    padding: 5%;
    background-color: white;
    transform: translateX(100%);
    transition: 1s ease transform;
    text-align: left;
    overflow-y: auto;
}

aside.visible {
    transform: translateX(0);
    width: 50%;
    border: 2px solid var(--DarkGrayBlue);
    border-radius: 5%;
}

/* body.sidebar-on{
    width:50%;
    align-items: left;
    padding:0%
} */

.sidebar-toggle{
    padding:0 .5%;
    border:none;
    border: 2px solid var(--DarkGrayBlue);
    border-radius: 0%;
    margin:0.5%;
    background:var(--DarkGrayBlue);
    font-weight:bold;
    color: white;
    ;
}
.sidebar-toggle:hover {
    color: var(--TanBeige);
    cursor: pointer;
            }

li{
    padding:1%;
}
.project-info{
    li{padding:1%};
    h3{text-align: center};
    text-align: left;   
}
